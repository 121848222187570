<template>
  <div id="app">
    <!-- <home></home> -->
    <router-view></router-view>
    <!-- 底部位置 -->
    <div class="bottom">
      <div class="title">华风新天信息技术（北京）有限责任公司</div>
      <div class="textbox">
        <div>
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402012039" style="display:inline-block">
            <p class="policetext">京ICP备19045442号-4</p>
          </a>
        </div>
        <div>华风新天©️2022版权所有</div>
        <div>
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402012039" style="display:inline-block">
            <p class="policetext"><em class="police"></em>京公网安备11011402012039号</p>
          </a>
        </div>
        <div>联系我们：service@weatherat.com </div>
      </div>
    </div>
  </div>
</template>
<script>
// import home from '@/views/home.vue'
export default {
  // components: {
  //   home
  // },
  data () {
    return {}
  },
  created () {},
  mounted () {
    const width = document.body.clientWidth
    const div = document.querySelector('.bottom')
    if (width < 1000) {
      div.style.width = '1440' + 'px'
    }
  }
}
</script>

<style lang="less">
//设置浏览器的滚动条
// ::-webkit-scrollbar {
//     width: 5px;
//     height: 5px;
// }
// ::-webkit-scrollbar-track-piece {
//     background-color: pink;
//     -webkit-border-radius: 6px;
// }
// ::-webkit-scrollbar-thumb:vertical {
//     height: 5px;
//     background-color: rgba(125, 125, 125, 0.7);
//     -webkit-border-radius: 6px;
// }

// ::-webkit-scrollbar-thumb:horizontal {
//     width: 5px;
//     background-color: rgba(125, 125, 125, 0.7);
//     -webkit-border-radius: 6px;
// }
* {
  padding: 0;
  margin: 0;
}
body {
  background-color: #f7f7f7;
  width: 100%;
}
.bottom {
  margin: 0 auto;
  padding: 83px 0 0;
  height: 155px;
  background: #000000;
  text-align: center;
  // position: relative;
  .title {
    font-size: 24px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    margin: 0 0 30px;
  }
  .textbox {
    // position: absolute;
    // left: 360px;
    // width: 1200px;
    // display: flex;
    // justify-content: space-evenly;
    font-size: 14px;
    font-family: Mulish-Regular, Mulish;
    font-weight: 400;
    color: #bdbdbd;
    div {
      display: inline-block;
      margin: 0 73px 0 0;
      a {
        text-decoration: none;
      }
    }
    :last-child {
      margin: 0;
    }
    a {
      text-decoration: none;
    }
    .policetext {
      font-size: 14px;
      font-family: Mulish-Regular, Mulish;
      font-weight: 400;
      color: #bdbdbd;
      .police {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url('./assets/police.png') no-repeat;
        background-size: 100% 100%;
        margin-right: 5px;
        vertical-align: -15%;
      }
    }
  }
}
</style>
