import axios from 'axios'

const request = axios.create({
  baseURL: 'https://data.weatherat.com'
})

// 定位前获取ip
export const getIp = () => {
  return request({
    url: '/live/ip',
    method: 'get'
  })
}

// 获取数据  和位置
export const get = (lat, lon) => {
  return request({
    url: `/golf/realtime/08a9c1ff7802a29e1fc1cad1280d1c74/${lat}/${lon}`,
    method: 'get'
  })
}

// // 获取城市id
// export const getCityId = (data) => {
//   return request({
//     url: '/location/getCityId',
//     method: 'post',
//     data
//   })
// }
