<template>
  <div>
    <!-- 上背景图位置 -->
    <div  class="topbox">
      <div class="top">
        <div><img src="../assets/logo.png" alt="" /></div>
        <div class="temp">
          <div><img src="../assets/定位icon@2x.png" alt="" /></div>
          <span class="city">{{dataObj.cityName}}</span> |
          <span class="temp1">{{dataObj.temp}}°</span>
          <div><img :src="dataObj.iconUrl" alt="" /></div>
        </div>
      </div>
      <div class="aritcle">
        <div class="left">
          <div class="title">玩转高球 尽在云间</div>
          <div class="text">
            云间高尔夫是高尔夫记分的移动应用程序，适用于希望提高自己的表现并连接到全球高尔夫社区的所有技能水平的高尔夫球手。
          </div>
          <div class="imgbox1">
            <div class="left1">
              <div class="box">
                <div class="androidimg"><img src="../assets/android.png" alt="" /></div>
                <div>
                  <div>Android</div>
                  <div>客户端下载</div>
                </div>
              </div>
              <div class="boximg">
                <img
                  class="img1"
                  src="../assets/微信图片_20220406150408.png"
                  alt=""
                />
              </div>
            </div>
            <div class="right">
              <div class="box">
                <div class="androidimg"><img src="../assets/ios.png" alt="" /></div>
                <div>
                  <div>IOS</div>
                  <div>客户端下载</div>
                </div>
              </div>
              <div class="boximg">
                <img
                  class="img1"
                  src="../assets/微信图片_20220406150408.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="imgbox"><img src="../assets/首屏幕.png" alt="" /></div>
      </div>
    </div>
    <!-- 中间显示 -->
    <div class="centerbox">
      <div class="fieldweatherat">
        <div class="left">
          <div class="title">球场天气</div>
          <div class="text">覆盖全国300+球场信息</div>
          <div class="text1">
            <div class="ciecle"></div>
            <div>实时更新球场库</div>
          </div>
          <div class="text1">
            <div class="ciecle"></div>
            <div>专业天气助力每一杆</div>
          </div>
          <div class="text1">
            <div class="ciecle"></div>
            <div>15日逐小时天气一览无余</div>
          </div>
        </div>
        <div class="right"><img src="../assets/球场详情1.png" alt="" /></div>
      </div>
      <div class="fieldweatherat2">
        <div class="right"><img src="../assets/记分板1.png" alt="" /></div>
        <div class="left">
          <div class="title">电子记分卡</div>
          <div class="text">实时同步的高效计分方式</div>
          <div class="text1">
            <div class="ciecle"></div>
            <div>支持多场计分</div>
          </div>
          <div class="text1">
            <div class="ciecle"></div>
            <div>使用快捷、简便</div>
          </div>
          <div class="text1">
            <div class="ciecle"></div>
            <div>高球游戏一网打尽</div>
          </div>
        </div>
      </div>
      <div class="fieldweatherat3">
        <div class="left">
          <div class="title">赛事广场</div>
          <div class="text">见证您高球生涯每一场球</div>
          <div class="text1">
            <div class="ciecle"></div>
            <div>创建专属球队</div>
          </div>
          <div class="text1">
            <div class="ciecle"></div>
            <div>队内赛、个人赛一较高下</div>
          </div>
          <div class="text1">
            <div class="ciecle"></div>
            <div>热门赛事等你参加</div>
          </div>
        </div>
        <div class="right"><img src="../assets/计分.png" alt="" /></div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { get, getIp } from '@/utils/request'
export default {
  data () {
    return {
      lat: '',
      lon: '',
      dataObj: {}
    }
  },
  mounted () {
    this.getCityIp()
    const width = document.body.clientWidth
    const div = document.querySelector('.topbox')
    if (width < 1000) {
      div.style.width = '1440' + 'px'
    }
  },
  methods: {
    // 定位获取位置
    getCityIp () {
      const that = this
      var geolocation1 = new BMap.Geolocation()
      geolocation1.getCurrentPosition(async function (r) {
        const res = await get(r.point.lat, r.point.lng)
        that.dataObj = res.data.data
      }, function (r) {
        that.getIpFn()
      }, { provider: 'baidu' })
    },

    // 当定位失败使用ip定位
    async getIpFn () {
      const res = await getIp()
      const { lat, lon } = res.data
      const res1 = await get(lat, lon)
      this.dataObj = res1.data.data
    }

  }
}
</script>

<style scoped lang="less">
.topbox {
  height: 954px;
  background: url("../assets/Slice 4.png") no-repeat;
  background-size: cover;
  margin: 0 0 76px;
  padding: 46px 0 0;
  .top {
    margin: 0 auto 230px;
    width: 1100px;
    display: flex;
    justify-content: space-between;
    .temp {
      display: flex;
      margin-top: 10px;
      height: 20px;
      padding: 9px 12px;
      font-size: 12px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #ffffff;
      border-radius: 100px;
      background-color: #000000;
      .city {
        margin: 0 10px;
      }
      .temp1 {
        margin:0 10px;
      }
      img {
        width: 20px;
        height: 20px;
        margin-top: -1px;
      }
    }
  }
  .aritcle {
    margin: 0 auto;
    width: 1100px;
    display: flex;
    justify-content:center;
    .left {
      margin-right:100px ;
      margin-left:-80px ;
      .title {
        width: 500px;
        font-size: 60px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 30px;
      }
      .text {
        width: 500px;
        font-size: 14px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 50px;
      }
      .imgbox1 {
        display: flex;
        .left1 {
          margin-right: 30px;
          background-color: #000000;
          padding: 10px 14px;
          border-radius: 10px;
          .box {
            display: flex;
            font-size: 10px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 5px;
            .androidimg{
              margin: 2px 8px 0;
            }
          }
          .boximg {
            background-color: #fff;
            width: 115px;
            height: 115px;
            padding: 3px;
          }
        }
        .right {
          background-color: #000000;
          padding: 10px 14px;
          border-radius: 10px;
          .box {
            display: flex;
            font-size: 10px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 5px;
            .androidimg{
              margin: 2px 5px 0;
            }
          }
          .boximg {
            background-color: #fff;
            width: 115px;
            height: 115px;
            padding: 3px;
          }
        }
        .img1 {
          width: 115px;
          height: 115px;
        }
      }
    }
    // .imgbox {
    //   img{

    //   }
    // }
  }
}
.centerbox {
  margin: 0 auto;
  width: 1440px;
  .fieldweatherat {
    margin: 0 auto 76px;
    display: flex;
    background: url("../assets/1.png") no-repeat;
    position: relative;
    width: 1100px;
    height: 497px;
    .left {
      margin: 50px 0 0 110px;
      .title {
        font-size: 40px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #000000;
      }
      .text {
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        margin-bottom: 44px;
      }
      .text1 {
        display: flex;
        font-size: 24px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.7);
        margin-bottom: 44px;
        .ciecle {
          margin-top: 10px;
          margin-right: 24px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: #ffc40f;
        }
      }
    }
    .right {
      position: absolute;
      right: 75px;
    }
  }
  .fieldweatherat2 {
    margin: 0 auto 76px;
    display: flex;
    justify-content: space-around;
    background: url("../assets/2.png") no-repeat;

    width: 1100px;
    height: 497px;
    .left {
      margin: 50px 60px 0 0;
      .title {
        font-size: 40px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #000000;
      }
      .text {
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        margin-bottom: 44px;
      }
      .text1 {
        display: flex;
        font-size: 24px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.7);
        margin-bottom: 44px;
        .ciecle {
          margin-top: 10px;
          margin-right: 24px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: #ffc40f;
        }
      }
    }
  }
  .fieldweatherat3 {
    margin: 0 auto 76px;
    display: flex;
    background: url("../assets/3.png") no-repeat;
    position: relative;
    width: 1100px;
    height: 497px;
    .left {
      margin: 50px 0 0 110px;
      .title {
        font-size: 40px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #000000;
      }
      .text {
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        margin-bottom: 44px;
      }
      .text1 {
        display: flex;
        font-size: 24px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.7);
        margin-bottom: 44px;
        .ciecle {
          margin-top: 10px;
          margin-right: 24px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: #ffc40f;
        }
      }
    }
    .right {
      position: absolute;
      right: 75px;
    }
  }
}
</style>
